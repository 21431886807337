import {SECTIONS} from 'data/work/pageBasics';

export const DATA = {
  meta: [
  ],
  pages: [
    {
      ...SECTIONS.sectionHeader,
      title: 'What I enjoy',
      path: '#what-i-enjoy',
    },
    {
      ...SECTIONS.section,
    },
  ]
}
